body {
  overflow-x: hidden;
}

.EstagiosTitle {
  display: flex;
  padding: 1rem 9rem;
  padding-bottom:10rem; 
}
.EstagiosTitle img {
  width: 100%;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.empresasImg {
  max-width: 100%;
  display: block;
}
.titleContainer{
  padding-top: 6rem;
  padding-left:4rem;
}

.internshipsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 2rem 7rem;
  padding-bottom: 40rem;
  overflow: hidden;
}
.estagio h1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  padding-bottom: 1rem;
  color:#787878;
}
.manyEstagiosContainer {
  display: flex;
  flex-direction: row;
  gap: 8rem;
} 
#tuu {
  align-items: center;
}
.bolasEstagios {
  position: absolute;
  right: 0px;
  top:1375px;
  z-index: -1;
}

.bolasEstagiosMobile {
  display: none;
}

@media (max-width: 1550px) {
  .bolasEstagios {
    right: -180px;
   top:1375px;
  }
}

@media (max-width: 1408px) {
  .bolasEstagios {
    right: -215px;
   top:1375px;
  }
}

@media (max-width: 1300px) {
  .bolasEstagios {
    right: -300px;
    top:1375px;
  }
}
@media (max-width: 1216px) {
  .bolasEstagios {
    right: -435px;
    top:1375px;
  }
}
@media (max-width: 1120px) {
  .manyEstagiosContainer {
    gap: 4rem;
  }
  .bolasEstagios {
    transform: scale(0.9);
    right: -320px;
    top:1375px;
  }
  .EstagiosTitle {
    padding: 1rem 6rem;
    padding-bottom: 10rem;
  }
}
@media (max-width: 1024px) {
  .bolasEstagios {
    right: -425px;
    top:1375px;
  }
}
@media (max-width: 995px) {
  .bolasEstagios {
    right: -500px;
    top:1375px;
  }
  .manyEstagiosContainer {
    gap: 1.5rem;
  }
}


  @media (max-width: 873px) {
    .manyEstagiosContainer {
      flex-direction: column;
      gap: 4.2rem;
    }
    .internshipsContainer {
      align-items: center;
      padding-bottom: 7rem;
    }
    .bolasEstagios {
      display: none;
    }
    .bolasEstagiosMobile {
      display: block;
      position: absolute;
      z-index: -1;
    }
    .bolasEstagiosMobileLeft {
      left: 0px;
      bottom: 1300px;
    }
    .bolasEstagiosMobileRight {
      right: 0px;
      bottom: 200px;
    }
    .titleContainer{
      padding:3rem;
    }
    .EstagiosTitle {
      padding: 1rem 2rem;
      padding-bottom: 10rem;
    }
  }

@media(max-width: 700px) {
  .internshipsContainer {
    padding: 2rem 2rem;
    padding-bottom: 7rem;
    gap: 4rem;
  }

  .estagio h1{
    text-align: center;
  }

}
