.filtersContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0rem 4rem;
    overflow: hidden;
}

.filters-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0rem 1rem;
    border-bottom: 2px solid Black; 
    max-width: 100%;
}

.filters-box > .dropdown-box:last-child {
    border-right:none;
}

.filters-inside-container {
    padding: 2rem 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem;
    color: Black;

}

.tipoooo {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    gap: 0.5rem; 
    cursor: pointer;
    font-size: 22px;
    position: relative; 
}

.circle {
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 0.813rem;
    border: 3px solid #000000;
    position: relative;
}

.selected-img{
    max-width: 100%;
    transform: scale(1.1);
}

@media (max-width: 1000px) {
    .tipoooo{
        font-size: 20px;
    }

    .filters-box {
        padding: 0rem 0rem;
    }
}

@media (max-width: 600px) {

    .filtersContainer {
        padding: 0rem 2rem;
    }

    .filters-inside-container {
        padding: 1rem 2rem;
        grid-template-columns: none;
    }

    .tipoooo{
        font-size: 12px;
    }

    .filters-box {
        padding: 0rem 0rem;
    }

    .circle {
        width: 1.5rem;
        height: 1.5rem;
    }
}