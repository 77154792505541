.dropdown-box {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 1rem 3rem;
    gap: 1.5rem;
    border-right: black 2px solid;
    margin-bottom: 1rem;
}

.dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;
    font-weight: 800;
    text-align: left;
    gap:2rem;
    cursor: pointer;
    transition: all 0.2s;
}

.dropdown-title.active {
    color: #BB00FF; 
}

.dropdown-title img {
    width: 2rem;
}

@media (max-width: 1200px) {
    .dropdown-box {
        padding: 1rem 2rem;
    }
    .dropdown-title {
        font-size: 25px;
    }
    .dropdown-title img {
        width: 1.5rem;
    }
}

@media (max-width:1000px) {
    .dropdown-title {
        font-size: 17px;
    }
    .dropdown-box{
        padding: 1rem;
    }
    .dropdown-title img {
        width: 1rem;
    }
}

@media (max-width: 600px) {

    .dropdown-title {
        font-size: 13px;
    }
    .dropdown-box{
        padding: 0.3rem;
    }
    .dropdown-title img {
        width: 1rem;
    }
}

@media (max-width: 500px) {
    .dropdown-title {
        font-size: 12px;
        gap:0.5rem;
    }
    .dropdown-box{
        padding: 0.2rem;
        margin-bottom: 0.5rem;
        max-height: 0.8rem;
    }
    .dropdown-title img {
        width: 0.5rem;
    }
}