.homepage-partners{
    position: relative;
    overflow: hidden;
}


.partners-title{
    display: flex;
    padding-right:7rem;
    padding-left:7rem;
}
.partners-title img{
    width:100%;
}

.partners-container{
    padding-top:2rem;
    padding-bottom:10rem;
    padding-right:5rem;
    padding-left:5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1rem;
}

.partners-container div{
    display: flex;
    align-items: center;
    justify-content: center;
    height:20rem;
    width:35%;
}

.bolasPartners1 {
    display: block;  
    position: absolute;
    right: -350px;
    top:1000px;
    z-index: -1;
}

.bolasPartners2 {
    display: block;  
    position: absolute;
    left: -220px;
    top:2000px;
    z-index: -1;
}


.partners-container div img {
    width: 100%;
    max-height: 100%;
    transition: all 0.4s;
}
.partners-container div img:hover{
    transform: scale(1.05);
}

.div-title {
    display: flex;
    justify-content: center;
}

.label-title {
    font-size: 72px;
    text-transform: uppercase;
}



.bolasMobile1 {
    display: none;  
}

.bolasMobile2 {
    display: none;  
}


@media (max-width:1250px){

    .bolasPartners1 {
        top: 800px;
        right: -450px;
    }
    
    .bolasPartners2 {

        left: -220px;
    }
}

@media (max-width:900px){ 
    
    .bolasPartners1 {
        display: none;  
    }

    .bolasPartners2 {
        display: none;  
    }

    .bolasMobile1 {
        display: block;  
        position: absolute;
        left: -140px;
        top:750px;
        z-index: -1;
    }

    .bolasMobile2 {
        display: block;  
        position: absolute;
        right: -200px;
        top:1200px;
        z-index: -1;
    }

    .label-title {
        font-size: 48px; 
    }

    .partners-title{
        padding-top:2rem;
        padding-right:1rem;
        padding-left:1rem;
    }

    .partners-container {
        gap:4rem;
    }

    .partners-container div{
        display: flex;
        align-items: center;
        justify-content: center;
        height:auto;
        width:50%;

    }
    
}

@media (max-width:600px){
    
    .label-title {
        font-size: 36px; 
    }
    
    .partners-container div img {
        width: 200%; 
        height: auto; 
    }
}

@media (max-width:400px){
    
    .label-title {
        font-size: 28px; 
    }
    
}

